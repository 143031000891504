import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Project = ({ image, title, description, slug }) => {
  return (
    <Link to={`/project/${slug}`} className="projects__item">
      <article>
        {/* <div class="project-wrapper"> */}
        <div className="projects__body">
          <h3>{title}</h3>
          <p>View Case Study</p>
        </div>
        {/* </div> */}
        <Img fluid={image.childImageSharp.fluid} alt={title} />
      </article>
    </Link>
  )
}
export default Project
