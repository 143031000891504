import React from "react"
import "./projects.scss"
import { Link } from "gatsby"
import Layout from "../layout/layout"
import Project from "../components/project/project"
import Wrapper from "../components/wrapper"

export default function ProjectsTemplate({
  pageContext: { projects, pageTitle, pageDescription },
}) {
  return (
    <Layout title={pageTitle} description={pageDescription}>
      <section>
        <Wrapper>
          <h1>
            Hi,
            <br />
            I'm Krystian,
            <br /> MERN Stack Developer.
          </h1>
          <div className="actions">
            <Link to="/#projects">View Projects </Link>
            <Link to="/about"> Read About Me</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </Wrapper>
      </section>
      <section id="projects">
        <h2>Projects</h2>
        <div className="projects">
          {projects.map(project => {
            const { frontmatter, id } = project
            const { image, title, description, slug } = frontmatter
            return (
              <Project
                key={id}
                image={image}
                title={title}
                description={description}
                slug={slug}
              />
            )
          })}
        </div>
      </section>
    </Layout>
  )
}
